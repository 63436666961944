import { dispatch, getState, RootState } from "~/old-app/redux";
import { Response } from "../../types/api";
import { Api } from "../api-store";
import {
  AddToCartBody,
  AddToCartResponse,
  Cart,
  CartDetails,
  CartListParams,
  Notification,
  OfflineCartBody,
} from "./cart-types";
import { CartActions } from "~/old-app/redux/cart-slice";
import { getCookie, removeCookie } from "~/old-app/utils/helpers/cookieManager";
import { CookieEnum } from "~/old-app/constants";

export const CartApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    cart: builder.query<Response<CartDetails>, CartListParams | undefined>({
      providesTags: (result, error, params) => [
        { type: "cart", payment_method: params?.payment_method },
      ],
      keepUnusedDataFor: 0,
      query: (params) => {
        const uuid = getCookie(CookieEnum.OfflineCartKey);
        return {
          url: `/get_card`,
          method: "GET",
          params: {
            ...params,
            uuid,
          },
        };
      },
      onQueryStarted() {
        dispatch(CartActions.setLoading(true));
      },
      transformResponse(res: Response<CartDetails>) {
        const state = getState() as RootState;
        if (state.auth.accessToken) {
          removeCookie(CookieEnum.OfflineCartKey);
        }
        dispatch(
          CartActions.setCart({
            cart: res.response.data,
            paymentMethods: res.response.meta?.payment_methods || [],
          })
        );
        return res;
      },
    }),
    offlineCart: builder.query<
      Response<CartDetails>,
      CartListParams | undefined
    >({
      providesTags: (result, error, params) => [
        { type: "offline-cart", ...params },
      ],
      query: (params) => ({
        url: `/offline-cart`,
        method: "GET",
        params,
      }),
      onQueryStarted() {
        dispatch(CartActions.setLoading(true));
      },
      transformResponse(res: Response<CartDetails>) {
        dispatch(
          CartActions.setCart({
            cart: res.response.data,
            paymentMethods: res.response.meta?.payment_methods || [],
          })
        );
        return res;
      },
    }),
    cartNotifications: builder.query<
      Response<Notification[]>,
      CartListParams | undefined
    >({
      providesTags: ["cart-notifications"],
      query: (params) => ({
        url: `/user/get-cart-notification`,
        method: "GET",
        params,
      }),
      transformResponse(res: Response<Notification[]>) {
        return res;
      },
    }),

    addToCart: builder.mutation<Response<AddToCartResponse>, AddToCartBody>({
      invalidatesTags: (result, error, {}) => [{ type: "cart" }],
      query: ({ ...data }) => ({
        url: `/order/add-to-cart`,
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    addOfflineToCart: builder.mutation<
      Response<AddToCartResponse>,
      AddToCartBody & {
        uuid?: string;
        service_id?: number | string;
      }
    >({
      invalidatesTags: (result, error, {}) => [{ type: "offline-cart" }],
      query: ({ service_id, uuid, ...data }) => ({
        url: `/offline-cart/${uuid}`,
        method: "POST",
        body: JSON.stringify(data),
        params: { service_id },
      }),
    }),
    updateOfflineToCart: builder.mutation<
      Response<AddToCartResponse>,
      AddToCartBody & {
        uuid?: string;
        service_id?: number | string;
      }
    >({
      invalidatesTags: (result, error, {}) => [{ type: "offline-cart" }],
      query: ({ service_id, uuid, ...data }) => ({
        url: `/offline-cart/${uuid}`,
        method: "PUT",
        body: JSON.stringify(data),
        params: { service_id },
      }),
    }),
    updateCartItem: builder.mutation<
      Response<AddToCartResponse>,
      AddToCartBody
    >({
      invalidatesTags: (result, error, {}) => [{ type: "cart" }],
      query: ({ ...data }) => ({
        url: `/order/update-cart`,
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),

    deleteCartItem: builder.mutation<Response<{}>, string | undefined>({
      invalidatesTags: ["cart"],
      query: (id) => ({
        url: `/orders/item/${id}`,
        method: "DELETE",
      }),
    }),

    deleteCartNotification: builder.mutation<Response<{}>, string | undefined>({
      invalidatesTags: ["cart-notifications"],
      query: (service_id) => ({
        url: `/user/delete-all-notification-cart`,
        method: "DELETE",
        params: { service_id },
      }),
      transformResponse(res: Response<{}>) {
        return res;
      },
    }),
    deleteOfflineCartItem: builder.mutation<
      Response<{}>,
      {
        service_id?: number;
        uuid?: string;
        item?: string;
      }
    >({
      invalidatesTags: ["offline-cart"],
      query: ({ service_id, uuid, item }) => ({
        url: `/offline-cart/${uuid}/${item}`,
        method: "DELETE",
        params: { service_id },
      }),
      transformResponse(res: Response<{}>) {
        return res;
      },
    }),
  }),
});

export const {
  useCartQuery,
  useCartNotificationsQuery,
  useOfflineCartQuery,

  useAddToCartMutation,
  useDeleteCartNotificationMutation,
  useUpdateCartItemMutation,
  useDeleteCartItemMutation,

  useAddOfflineToCartMutation,
  useUpdateOfflineToCartMutation,
  useDeleteOfflineCartItemMutation,
} = CartApi;
