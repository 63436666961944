import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { ApiQueryParams } from "~/old-app/types/api";
import {
  useAddToCartMutation,
  useCartNotificationsQuery,
  useCartQuery,
  useOfflineCartQuery,
} from "./cart-api";
import { AddToCartBody, OfflineCartItem } from "./cart-types";
import { CartActions, useCartSlice } from "~/old-app/redux/cart-slice";
import { useState } from "react";
import { dispatch } from "~/old-app/redux";
import { getCookie } from "~/old-app/utils/helpers/cookieManager";
import { CookieEnum } from "~/old-app/constants";

export const useCart = () => {
  const { accessToken, isInfluencer } = useAuthSlice();
  const { selectedPaymentMethod } = useCartSlice();
  const { selectedService, district_id } = useConfigSlice();
  const { data, isLoading, isFetching } = useCartQuery(
    {
      service_id: selectedService,
      payment_method: selectedPaymentMethod?.unique_name || "",
    },
    { skip: !selectedService || !accessToken || isInfluencer || !district_id }
  );

  return {
    cart: data?.response?.data || [],
    isLoading,
    isFetching,
  };
};
export const useOfflineCart = () => {
  const { accessToken, isInfluencer } = useAuthSlice();
  const { selectedService, district_id } = useConfigSlice();
  // const { offlineCartItems } = useCartSlice();
  const uuid = getCookie(CookieEnum.OfflineCartKey);

  const { data, isLoading, isFetching } = useOfflineCartQuery(
    { service_id: selectedService, uuid },
    { skip: !selectedService || !!accessToken || isInfluencer || !district_id }
  );

  return {
    cart: data?.response?.data || [],
    isLoading,
    isFetching,
  };
};
export const useCartNotifications = (props?: ApiQueryParams) => {
  const { data, isLoading, isFetching } = useCartNotificationsQuery(props, {
    skip: !props?.selectedService,
  });

  const carts = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total > carts.length;

  return {
    cartNotifications: data?.response?.data || [],
    isLoading,
    isFetching,
    hasMore,
  };
};

export const useSyncCart = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { offlineCartItems } = useCartSlice();
  const [addToCartMutate] = useAddToCartMutation();
  const sync = async () => {
    setIsLoading(true);
    const promises =
      offlineCartItems?.map((item: OfflineCartItem) => {
        const body: AddToCartBody = {
          id: item.id,
          qty: item.qty,
          order_type: "web",
          delete_old_cart: false,
          custom_options: item?.custom_options || {},
          key: item.key || "",
        };
        return addToCartMutate(body)
          .unwrap()
          .catch((error) => {
            console.error("Failed to add item to cart:", error);
          });
      }) || [];

    try {
      await Promise.all(promises);
      dispatch(CartActions.emptyCart());
    } catch (error) {
      console.error("Failed to sync cart:", error);
    }
    setIsLoading(false);
  };

  return {
    sync,
    isLoading,
  };
};
